<template>
  <b-card>
    <validation-observer
      #default="{ invalid, handleSubmit }"
    >
      <!-- Form -->
      <b-form
        @submit.prevent="handleSubmit(createPrivilege)"
      >

        <!-- Actions -->
        <b-row class="mb-4">
          <b-col>
            <b-button
              variant="secondary"
              class="float-right"
              size="sm"
              type="submit"
              :disabled="invalid"
            >
              <span class="text-nowrap">Save Changes</span>
            </b-button>
          </b-col>
        </b-row>

        <!-- Fields -->
        <privileges-form :privilege-data="privilegeData" />

        <b-row>
          <b-col>
            <modules-table
              v-if="modules && modules.length > 0"
              :modules="modules"
              @selected-row="fetchMenus"
            />
          </b-col>

          <b-col
            v-if="modules && modules.length > 0 && menus && menus.length > 0"
            sm="6"
          >
            <menus-table :menus="menus" />
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>

  </b-card>
</template>

<script>
import {
  BCard, BForm, BRow, BCol, BButton,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'

import PrivilegesForm from '../privileges-form/PrivilegesForm.vue'
import ModulesTable from '../../modules-and-menus/modules/modules-table/ModulesTable.vue'
import MenusTable from '../../modules-and-menus/menus/menus-table/MenusTable.vue'

import usePrivilegesForm from '../privileges-form/usePrivilegesForm'
import useModules from '../../modules-and-menus/modules/useModules'
import useMenus from '../../modules-and-menus/menus/useMenus'

export default {
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BButton,

    //  Local Component
    ModulesTable,
    MenusTable,

    // Form Validation
    ValidationObserver,
    PrivilegesForm,
  },
  data() {
    return {
      required,
      email,
    }
  },

  setup() {
    const {
      privilegeData,

      createPrivilege,
    } = usePrivilegesForm()

    const {
      fetchModules,
      modules,
    } = useModules()

    fetchModules()

    const {
      menus,

      fetchMenus,
      clearMenusState,
    } = useMenus()

    clearMenusState()

    return {
      privilegeData,
      modules,
      menus,

      fetchModules,
      fetchMenus,
      createPrivilege,
    }
  },
}
</script>
